import { configureStore } from "@reduxjs/toolkit";
import credentialsReducer from '../features/credentials/credentialsSlice';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: {
    credentials: credentialsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;