import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CredentialsState } from '../../models/CredentialsState';
import { LoadingState } from '../../models/enums';
import axios from 'axios';
import * as jose from 'jose';
import { RootState } from '../../app/store';

const getProviderId = (decodedToken: any) => {
  const groups = decodedToken['cognito:groups'];
  let result;
  for (const i in groups) {
    const indexOfProvider = groups[i].indexOf('Provider');

    if (indexOfProvider === 0) {
      result = groups[i].substring(10);
    }
  }
  return result;
}

export const fetchToken = createAsyncThunk('credentials/fetchToken', async (payload: { username: string, password: string }) => {
  try {
    const data = {
      AuthParameters: {
        USERNAME: payload.username,
        PASSWORD: payload.password
      },
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: '58jcnn51q51crrlmvrque7q0du'
    }
    const response = await axios.post('https://cognito-idp.us-east-1.amazonaws.com/us-east-1_us-east-1_foZ45Yf8D/', data, {
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
      }
    });
    console.log('Cognito response', response);
    const idToken = response.data.AuthenticationResult.IdToken;
    const decodedToken = jose.decodeJwt(idToken);
    const providerId = getProviderId(decodedToken);
    return { token: idToken, providerId };
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const initialState: CredentialsState = {
  username: '',
  password: '',
  token: '',
  providerId: '',
  fetchingCredentials: LoadingState.IDLE,
};

const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setUsername: (state: CredentialsState, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setPassword: (state: CredentialsState, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.fetchingCredentials = LoadingState.LOADING;
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        const result: any = action.payload;
        state.token = result.token;
        state.providerId = result.providerId;
        state.fetchingCredentials = LoadingState.LOADED;
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.token = '';
        state.providerId = '';
        state.fetchingCredentials = LoadingState.ERROR;
      });
  }
});

export const usernameSelector = (state: RootState): string => state.credentials.username;
export const passwordSelector = (state: RootState): string => state.credentials.password;
export const tokenSelector = (state: RootState): string => state.credentials.token;
export const providerIdSelector = (state: RootState): string => state.credentials.providerId;
export const fetchingCredentialsSelector = (state: RootState): LoadingState => state.credentials.fetchingCredentials;

export const { setUsername, setPassword } = credentialsSlice.actions;

export default credentialsSlice.reducer;