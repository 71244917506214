export const registerUserMutation = `
mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
        __typename
        ... on RegisterUserPayload {
            msisdn
            pin
        }
        ... on RegisterUserError {
            msisdn
            errorCode
            errorMessage
        }
    }
}
`;

export const resetPinMutation = `
mutation ResetPin($input: ResetPinInput!) {
    resetPin(input: $input) {
        __typename
        ... on ResetPinPayload {
            msisdn
            pin
        }
        ... on ResetPinError {
            msisdn
            errorCode
            errorMessage
        }
    }
}
`;

export const createBalanceTransferMutation = `
mutation TransferBalance($input: CreateBalanceTransferInput!) {
    createBalanceTransfer(input: $input) {
        __typename
        ... on CreateBalanceTransferPayload {
            senderMsisdn
            receiverMsisdn
            amount
            vatAmount
            transactionId
            status
        }
        ... on CreateBalanceTransferError {
            senderMsisdn
            receiverMsisdn
            amount
            errorCode
            errorMessage
        }
    }
}
`;