import { Alert, Box, Grid, TextField } from "@mui/material";
import './TransferBalance.css';
import { PhoneIcon } from "../../components/icons/PhoneIcon";
import { useRef, useState } from "react";
import { createBalanceTransferMutation } from "../../utils/mutations";
import axios, { AxiosError } from "axios";
import { GRAPHQL_URL } from "../../utils/constants";
import { useSelector } from "react-redux";
import { tokenSelector } from "../credentials/credentialsSlice";

export const TransferBalance: React.FC = () => {
  const token: string = useSelector(tokenSelector);

  const [ussdCode, setUssdCode] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');
  const [creatingTransferBalance, setCreatingTransferBalance] = useState<boolean>(false);

  const resultAlertContainer = useRef(null);

  // *200*PIN*amount*MSISDN*MSISDN#
  // *200*1234*30*256789012345*256789012346#

  const createTransferBalance = async () => {
    if (creatingTransferBalance) {
      return;
    }

    if (!ussdCode) {
      setAlertMessage('Please enter the USSD code');
      setAlertSeverity('error');
      return;
    }
    const ussdPattern = /^\*200\*(\d+)\*(\d+)\*(\d+)\*(\d+)\#$/
    const match = ussdCode.match(ussdPattern);

    if (match) {
      const [, pin, amount, senderMsisdn, receiverMsisdn] = match;
      console.log(`PIN: ${pin}, Amount: ${amount}, Sender MSISDN: ${senderMsisdn}, Receiver MSISDN: ${receiverMsisdn}`);
      setCreatingTransferBalance(true);
      try {
        const result = (await axios.post(
          GRAPHQL_URL,
          { query: createBalanceTransferMutation, variables: { input: {
            senderMsisdn,
            receiverMsisdn,
            amount,
            pin
          } } },
          {
            headers: {
              'Authorization': token
            }
          }
        ) as any).data.data.createBalanceTransfer;
        if (result.errorCode) {
          setAlertMessage(result.errorMessage);
          setAlertSeverity('error');
        } else {
          const amount: number = result.amount;
          const vatAmount: number = result.vatAmount;
          const transactionId: string = result.transactionId;
          setAlertMessage(`Balance Transfer completed - ${amount}SDG VAT ${vatAmount}SDG. Txn ID: ${transactionId}`);
          setAlertSeverity('success');
        }
        console.log(result);
      } catch (error: any) {
        error = error as AxiosError;
        console.error(error);
        if (error.response?.status === 401) {
          setAlertMessage('Invalid token');
          setAlertSeverity('error');
        } else {
          setAlertMessage('Error processing request');
          setAlertSeverity('error');
        }
      }
      setCreatingTransferBalance(false);
    } else {
      setAlertMessage('Invalid USSD code format. Please use the format *200*PIN*amount*MSISDN*MSISDN#');
      setAlertSeverity('error');
    }
  }
  
  return (
    <>
      {/*<Box sx={{ mx: 'auto', width: '360px', pb: 5, pt: 5}}>
        <TextField
          type="text"
          label="Sender Number"
          placeholder="Enter Sender Number"
          variant="filled"
          size="small"
          className="w-full"
          name="senderMsisdn"
        />
      </Box>*/}
      <div className="iphone-x phone-demo-container">
        <i>Speaker</i>
        <b>Camera</b>
        <br /><br /><br />
        <div style={{ position: 'relative', width: '0px', height: '0px', top: '-20px' }} ref={resultAlertContainer}>
          {!creatingTransferBalance && alertMessage && <Alert sx={{ width: '360px' }} severity={alertSeverity}>{alertMessage}</Alert>}
          {creatingTransferBalance && <Alert sx={{ width: '360px' }} severity="info">Creating balance transfer...</Alert>}
        </div>
        <TextField
          type="text"
          placeholder="Enter USSD Code"
          variant="filled"
          size="small"
          name="receiverMsisdn"
          InputProps={{ disableUnderline: true }}
          className="w-full"
          aria-label="callednumber"
          sx={{ mt: 9 }}
          value={ussdCode}
          onChange={(e) => setUssdCode(e.target.value)}
        />
        <Grid container className="w-full">
          <Grid item xs={12} sx={{ py: 25, display: 'flex', justifyContent: 'center' }}>
            <PhoneIcon onClick={createTransferBalance} />
          </Grid>
        </Grid>
        <span>Left action button</span>
        <span>Right action button</span>
        <br />
      </div>
    </>
  );
}