import { Box, AppBar, Toolbar, IconButton, Typography, Tabs, Tab } from "@mui/material";
import TotogiLogoWhiteSvg from "../assets/TotogiLogoWhite.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const MainNavbar: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = useState<string>('/register');

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: 'rgb(0, 29, 61)' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Box component="img" src={TotogiLogoWhiteSvg} alt="logo" />
          </Typography>
          <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} className="navbar-tabs">
            <Tab label="Transfer" value="/transfer_balance" component={NavLink} to={"/transfer_balance"} />
            <Tab label="Register" value="/register" component={NavLink} to={"/register"} />
          </Tabs>
        </Toolbar>
      </AppBar>
    </Box>
  );
};