import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { useState } from "react";
import { registerUserMutation, resetPinMutation } from "../../utils/mutations";
import axios, { AxiosError } from "axios";
import { GRAPHQL_URL } from "../../utils/constants";
import { useSelector } from "react-redux";
import { tokenSelector } from "../credentials/credentialsSlice";

export const Register: React.FC = () => {
  const token: string = useSelector(tokenSelector);

  const [msisdn, setMsisdn] = useState<string>('');
  const [processingRegistration, setProcessingRegistration] = useState<boolean>(false);
  const [processingReset, setProcessingReset] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');

  const registerUser = async () => {
    console.log(msisdn);
    setProcessingRegistration(true);
    try {
      const result = (await axios.post(
        GRAPHQL_URL,
        { query: registerUserMutation, variables: { input: { msisdn } } },
        {
          headers: {
            'Authorization': token
          }
        }
      ) as any).data.data.registerUser;
      if (result.errorCode) {
        setAlertMessage(result.errorMessage);
        setAlertSeverity('error');
      } else {
        setAlertMessage(`Registration successful - PIN ${result.pin}`);
        setAlertSeverity('success');
      }
      console.log(result);
    } catch (error: any) {
      error = error as AxiosError;
      console.error(error);
      if (error.response?.status === 401) {
        setAlertMessage('Invalid token');
        setAlertSeverity('error');
      } else {
        setAlertMessage('Error processing request');
        setAlertSeverity('error');
      }
    }
    setProcessingRegistration(false);
  };

  const resetPin = async () => {
    console.log(msisdn);
    setProcessingReset(true);
    try {
      const result = (await axios.post(
        GRAPHQL_URL,
        { query: resetPinMutation, variables: { input: { msisdn } } },
        {
          headers: {
            'Authorization': token
          }
        }
      ) as any).data.data.resetPin;
      if (result.errorCode) {
        setAlertMessage(result.errorMessage);
        setAlertSeverity('error');
      } else {
        setAlertMessage(`Reset PIN successful - PIN ${result.pin}`);
        setAlertSeverity('success');
      }
      console.log(result);
    } catch (error: any) {
      error = error as AxiosError;
      console.error(error);
      if (error.response?.status === 401) {
        setAlertMessage('Invalid token');
        setAlertSeverity('error');
      } else {
        setAlertMessage('Error processing request');
        setAlertSeverity('error');
      }
    }
    setProcessingReset(false);
  };

  return (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '400px', height: '100vh', py: 5, mx: 'auto' }}>
    <Typography variant="h4" sx={{ mb: 2 }}>
      Register
    </Typography>
    <TextField
      required
      disabled={processingRegistration || processingReset}
      label="MSISDN"
      type="tel"
      variant="outlined"
      value={msisdn}
      onChange={(e) => setMsisdn(e.target.value)}
      sx={{ mb: 2, width: '100%' }}
    />
    {alertMessage && 
      <Alert severity={alertSeverity} sx={{ width: '100%', mb: 1 }}>
        {alertMessage}
      </Alert>
    }
    <Box sx={{ display: 'flex' }}>
      <Button disabled={processingRegistration || processingReset} type="submit" variant="contained" color="primary" onClick={registerUser} sx={{ mr: 2 }}>
        {processingRegistration ? 'Processing...' : 'Register'}
      </Button>
      <Button disabled={processingReset || processingRegistration} type="submit" variant="contained" color="primary" onClick={resetPin}>
        {processingReset ? 'Processing...' : 'Reset PIN'}
      </Button>
    </Box>
  </Box>
  );
};