import { ReactNode, useEffect, useState } from "react";
import { MainNavbar } from "./MainNavbar";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { useAppDispatch } from "../app/store";
import { fetchingCredentialsSelector, passwordSelector, usernameSelector, fetchToken, setUsername, setPassword, tokenSelector } from "../features/credentials/credentialsSlice";
import { useSelector } from "react-redux";
import { LoadingState } from "../models/enums";

export const PageLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const username: string = useSelector(usernameSelector);
  const password: string = useSelector(passwordSelector);
  const token: string = useSelector(tokenSelector);
  const fetchingCredentials: LoadingState = useSelector(fetchingCredentialsSelector);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');

  useEffect(() => {
    switch (fetchingCredentials) {
      case LoadingState.LOADED:
        setAlertText('Successfully loaded token');
        setAlertSeverity('success');
        break;
      case LoadingState.ERROR:
        setAlertText('Error setting credentials, kindly check them');
        setAlertSeverity('error');
        break;
      default:
        return;
    }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, [fetchingCredentials]);

  const loadCredentials = () => {
    if (!username || !password) {
      setAlertText('Please fill both username and password');
      setAlertSeverity('error');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    dispatch(fetchToken({ username, password }));
  }

  return <>
    <MainNavbar />
    <Box sx={{ p: 3, display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6" sx={{ width: '150px', display: 'inline-block' }}>API Credentials</Typography>
      <TextField value={username} disabled={fetchingCredentials === LoadingState.LOADING} onChange={(event) => dispatch(setUsername(event?.target?.value))} label="Username" variant="outlined" size="small" sx={{ width: '300px', pr: 2 }} />
      <TextField value={password} disabled={fetchingCredentials === LoadingState.LOADING} onChange={(event) => dispatch(setPassword(event?.target?.value))} label="Password" type="password" size="small" variant="outlined" sx={{ width: '300px', pr: 2 }} />
      <Button disabled={fetchingCredentials === LoadingState.LOADING} variant="outlined" sx={{ minHeight: '40px' }} onClick={loadCredentials}>
        {fetchingCredentials === LoadingState.LOADING ? 'Setting Credentials...' : 'Set Credentials'}
      </Button>
      {showAlert && 
        <Alert sx={{ maxHeight: '40px', ml: 2, alignItems: 'center' }} severity={alertSeverity}>
          {alertText}
        </Alert>
      }
      {!showAlert && token && 
        <Alert sx={{ maxHeight: '40px', ml: 2, alignItems: 'center' }} severity='info'>
          Credentials Ready
        </Alert>
      }
    </Box>
    {children}
  </>;
};